import { CS_State, StoreCardDispensary } from 'services';

import AccordionRow from './AccordionRow';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { Loader } from '../Account/Loader/loader';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';

import './store-list-accordion.scss';

export type StoreListAccordionProps = {
  anchor?: string;
  loading?: boolean;
  storesByState: StoresByStateType[];
};

export type StoresByStateType = Pick<CS_State, 'curaleaf_com' | 'title'> & {
  stores: StoreCardDispensary[];
};

export const StoreListAccordion = ({
  anchor = 'store-list-accordion',
  loading = false,
  storesByState
}: StoreListAccordionProps): JSX.Element | null => {
  return (
    <PackageComponentWrapper>
      <ErrorBoundary component="store-list-accordion">
        <div
          id={anchor}
          data-loading={loading}
          className="store-list-accordion container"
          data-test="accordion">
          {loading ? (
            <Loader />
          ) : (
            storesByState.map((state, i) => (
              <AccordionRow
                key={`${state.title}-${i}`}
                storesPerState={state}
              />
            ))
          )}
        </div>
      </ErrorBoundary>
    </PackageComponentWrapper>
  );
};
