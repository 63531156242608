'use client';

import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import qs from 'qs';
import classNames from 'classnames';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider, closeSnackbar, enqueueSnackbar } from 'notistack';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { isIOS, osVersion } from 'react-device-detect';

import {
  AgeVerification,
  DispensaryTitleBanner,
  FooterNavigation,
  HeaderNavigation,
  LicenseBar,
  MailingOptInModal,
  SiteWideContextProvider,
  WideBanner,
  useStorage
} from 'ui';
import { LayoutProps, WebsiteData } from '@/app/layout';

interface LayoutComponentProps extends LayoutProps {
  website: WebsiteData;
  isPreview: boolean;
}

const queryClient = new QueryClient();

export default function Layout({
  children,
  isPreview,
  website
}: LayoutComponentProps) {
  const [navOpen, setNavOpen] = useState(false);
  const [wideBanner, setWideBanner] = useStorage('wideBanner', '');
  const [ageConfirm, setAgeConfirm] = useStorage<'0' | '1'>(
    'confirmed21OrOlder',
    '0',
    process.env.CONTENTSTACK_ENVIRONMENT?.includes('ct') || false
  );
  const [promptedForMailing] = useStorage('promptedForMailingList', '0');

  const [optInModalOpen, setOptInModalOpen] = useState(false);
  const [ageVerificationOpen, toggleAgeVerification] = useState(false);

  // Detects known problematic iOS versions
  const isOldBrowser = (isIOS && parseFloat(osVersion) < 16) || false;

  useEffect(() => {
    if (ageConfirm !== '1') {
      toggleAgeVerification(true);
    } else {
      const timer = website?.optIn?.delay ? website.optIn.delay : 1000;
      setTimeout(() => {
        const now = Date.now();
        const query = window?.location?.search?.length
          ? qs.parse(window.location.search.substring(1))
          : {};

        // Bypasses pop up if it is a UTM link (Paid Ad)
        if (
          query.utm_medium &&
          typeof query.utm_medium === 'string' &&
          query.utm_medium.toLowerCase() === 'cpc'
        ) {
          return;
        }
        if (!promptedForMailing || promptedForMailing !== '1') {
          if (now < JSON.parse(promptedForMailing)) {
            return;
          } else if (
            now > JSON.parse(promptedForMailing) ||
            (promptedForMailing && !open)
          ) {
            setOptInModalOpen(true);
          }
        }
      }, timer);
    }
  }, [ageConfirm]);

  const onConfirmAge = () => {
    setAgeConfirm('1');
    toggleAgeVerification(false);
    if (isOldBrowser) {
      Cookies.set('isLegacyBrowser', '1', { expires: 31 });
      enqueueSnackbar(
        'Looks like your browser is not quite up to date. Please update your browser and OS for the best experience.',
        {
          preventDuplicate: true,
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top'
          },
          variant: 'warning',
          persist: true,
          action: (snackbarId) => (
            <button
              className="cart-button body-m btn primary pointer"
              style={{ height: '30px' }}
              onClick={() => closeSnackbar(snackbarId)}>
              Dismiss
            </button>
          )
        }
      );
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const ageconfirm = process.env.CONTENTSTACK_ENVIRONMENT?.includes('ct')
        ? window.sessionStorage.getItem('confirmed21OrOlder')
        : window.localStorage.getItem('confirmed21OrOlder');
      if (ageconfirm && ageconfirm.includes('1')) {
        toggleAgeVerification(false);
        clearInterval(intervalId);
      }
    }, 1);
    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (wideBanner !== '0' && website.wideBanner.widebanner?.length) {
      setWideBanner('1');
    }
  }, [wideBanner]);

  const toggleNav = (val: boolean) => {
    setNavOpen(val);
  };

  const isStateSite =
    process.env.CONTENTSTACK_ENVIRONMENT.includes('ct') ||
    process.env.CONTENTSTACK_ENVIRONMENT.includes('nd');

  return (
    <>
      <SnackbarProvider>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools />
          <SiteWideContextProvider
            contextProps={{
              isPreview
            }}>
            {ageVerificationOpen ? (
              <AgeVerification
                {...website.ageVerification}
                submit={() => onConfirmAge()}
              />
            ) : null}
            {promptedForMailing === '0' &&
            !isOldBrowser &&
            website.optIn.embeddable_formConnection.edges[0] ? (
              <MailingOptInModal
                form={website.optIn}
                formConfig={website.formConfig}
                open={optInModalOpen}
                toggle={() => setOptInModalOpen(false)}
              />
            ) : null}
            {wideBanner === '1' && website.wideBanner.widebanner?.length ? (
              <WideBanner
                {...website.wideBanner}
                onClose={() => setWideBanner('0')}
              />
            ) : null}
            <DispensaryTitleBanner />
            <HeaderNavigation
              isKiosk={false}
              navLinks={website.header.header}
              navOpen={navOpen}
              socialLinks={website.header.socialLinks}
              toggleNav={toggleNav}
              userLinks={
                process.env.NEXT_PUBLIC_ENABLE_ORDER_HISTORY === 'true'
                  ? website.header.userLinks
                  : website.header.userLinks.filter(
                      (link) => link.title !== 'Orders'
                    )
              }
            />
            <main
              className={classNames('layout', {
                [`min-height-web`]: !isStateSite,
                [`min-height-web-ct`]:
                  isStateSite &&
                  process.env.CONTENTSTACK_ENVIRONMENT.includes('ct'),
                [`min-height-web-nd`]:
                  isStateSite &&
                  process.env.CONTENTSTACK_ENVIRONMENT.includes('nd'),
                'disable-scroll': ageVerificationOpen
              })}>
              <div>{children}</div>
            </main>
            <LicenseBar />
            <FooterNavigation
              {...website.footer}
              states={website.states}
              forKiosk={false}
            />
          </SiteWideContextProvider>
        </QueryClientProvider>
      </SnackbarProvider>
    </>
  );
}
