import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';

import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { CTAButton, CTAButtonProps } from '../CTAButton';

import './inactivity-timer.scss';

export type InactivityProps = {
  button: CTAButtonProps;
  inactivePrompt: boolean;
  lastInteractedTime?: number;
  remainingTime?: number;
  setInactivePrompt: () => void;
  timer?: {
    popup: number;
    dismiss: number;
  };
  title: string;
};

export const InactivityTimer = ({
  button,
  inactivePrompt,
  lastInteractedTime,
  remainingTime,
  setInactivePrompt,
  timer,
  title
}: InactivityProps) => {
  return (
    <PackageComponentWrapper>
      <Dialog
        data-test="inactivity"
        className="inactivity"
        visible={inactivePrompt}
        onHide={() => setInactivePrompt()}
        dismissableMask
        header={title}>
        <CTAButton
          data-test="inactivity-btn"
          {...button}
          callBackFunc={() => setInactivePrompt()}
        />
        <div data-test="inactivity-timer" className="inactivity-timer">
          <ProgressSpinner data-test="inactivity-spinner" />
          <div data-test="inactivity-time" className="inactivity-time">{`${
            timer && timer.popup && lastInteractedTime
              ? Math.ceil(
                  (timer.popup - (Date.now() - lastInteractedTime)) / 1000
                )
              : remainingTime
          }s`}</div>
        </div>
      </Dialog>
    </PackageComponentWrapper>
  );
};
