import { Metadata } from 'next';
import { CS_Seo } from 'services';
import { optionalImageMap } from './contentstackMap';

const sanitizeString = (str: string): string => {
  if (typeof str != 'string') return str;
  const trimmed = str.trim();
  if (trimmed.length) return trimmed;
  return '';
};

export const seoMap = (seo: CS_Seo): Metadata => {
  const image = optionalImageMap(seo.imageConnection);

  const openGraph: Metadata['openGraph'] = {
    type: 'website',
    locale: 'en_us',
    title: sanitizeString(seo.page_title),
    description: sanitizeString(seo.page_description),
    images: image ? [image] : [],
    siteName: 'Curaleaf'
  };

  return {
    description: sanitizeString(seo.page_description || ''),
    openGraph: { ...openGraph },
    title: sanitizeString(seo.page_title || '')
  };
};
